import { Link } from "gatsby"
import React from "react"

export default function Tools() {
  return (
    <div>
      <Link to="azure-resource-namer">Azure Resource Namer</Link>
    </div>
  )
}
